import { useEffect, useRef } from 'react';

export const useTimeout = (time: number, cb: () => void) => {
  const timerId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    timerId.current = setTimeout(cb, time);

    return () => clearTimeout(timerId.current);
  }, [time, cb]);
};

import { useTimeout } from '@/hooks/useTimeout';
import { useEffect, useState } from 'react';
import { TrackingService } from '@/service/tracking/trackingService';

type Config = {
  timeOut: number;
  scrollPercentage: number;
  eventCb: () => void;
};

export const useTimerScrollTracking = (config: Config) => {
  const [inView, setInView] = useState(false);
  const [tracked, setTracked] = useState(false);

  const [timerUp, setTimerUp] = useState(false);
  useTimeout(config.timeOut, () => setTimerUp(true));

  // Observe the pixel and set state when it's in view
  useEffect(() => {
    const marker = TrackingService.createTrackingMarker(`${config.scrollPercentage}%`);

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0 },
    );

    if (inView) {
      observer.disconnect();
      return;
    }

    observer.observe(marker);

    return () => {
      observer.disconnect();
      document.body.removeChild(marker);
    };
  }, [config.scrollPercentage, inView]);

  // Trigger the tracking event only if both conditions are met
  // (timer is up and element is in view)
  useEffect(() => {
    if (timerUp && inView && !tracked) {
      console.log('timerUp and inView', config.eventCb);
      config.eventCb();
      setTracked(true);
    }
  }, [timerUp, inView, config.eventCb, config, tracked]);
};
